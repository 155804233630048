export const generateEuclideanSequence = (
  length: number,
  pulses: number,
): number[] => {
  const sequence = new Array(length);
  let bucket = 0;
  for (let i = length - 1; i >= 0; i--) {
    bucket += pulses;
    if (bucket >= length) {
      bucket -= length;
      sequence[i] = 1;
    } else {
      sequence[i] = 0;
    }
  }
  return sequence;
};
