import { generateEuclideanSequence } from '../audioUtils/generateEuclideanSequence';

registerProcessor(
  'EuclideanRhythmProcessor',
  class EuclideanRhythmProcessor extends AudioWorkletProcessor {
    static get parameterDescriptors() {
      return [
        {
          name: 'clock',
          defaultValue: 0,
          minValue: -1,
          maxValue: 1,
          automationRate: 'k-rate',
        },
        {
          name: 'reset',
          defaultValue: 0,
          minValue: -1,
          maxValue: 1,
          automationRate: 'k-rate',
        },
        {
          name: 'length',
          defaultValue: 0,
          minValue: 0,
          maxValue: 99,
          automationRate: 'k-rate',
        },
        {
          name: 'pulses',
          defaultValue: 0,
          minValue: 0,
          maxValue: 99,
          automationRate: 'k-rate',
        },
        {
          name: 'rotation',
          defaultValue: 0,
          automationRate: 'k-rate',
        },
      ];
    }

    lastClock = 0;
    lastReset = 0;
    position = -1;
    lastLength = 0;
    lastPulses = 0;
    lastRotation = 0;
    sequence: number[] = [];

    process(
      _: Float32Array[][],
      [[output]]: Float32Array[][],
      {
        clock: [clock],
        reset: [reset],
        length: [floatLength],
        pulses: [floatPulses],
        rotation: [floatRotation],
      }: { [param: string]: Float32Array },
    ): boolean {
      const length = Math.floor(floatLength);
      if (length <= 0) return true;

      const pulses = Math.floor(floatPulses);
      const rotation = Math.floor(floatRotation);

      let trigger = false;
      if (this.lastReset <= 0 && reset > 0) {
        this.position = 0;
        this.port.postMessage(['position', 0]);
        trigger = true;
      } else if (this.lastClock <= 0 && clock > 0) {
        this.position = (this.position + 1) % Math.ceil(length);
        this.port.postMessage(['position', this.position]);
        trigger = true;
      }

      this.lastReset = reset;
      this.lastClock = clock;

      if (trigger) {
        if (length !== this.lastLength || pulses !== this.lastPulses) {
          this.sequence = generateEuclideanSequence(length, pulses);
          this.lastLength = length;
          this.lastPulses = pulses;
          this.port.postMessage(['sequence', this.sequence]);
        }
        if (rotation !== this.lastRotation) {
          this.lastRotation = rotation;
          this.port.postMessage(['rotation', rotation]);
        }

        output[0] =
          this.sequence[
            (this.position + length + (rotation % length)) % length
          ];
      }

      return true;
    }
  },
);
